/* eslint-env es2020, browser */
import React, { useState, useEffect } from "react";
import Web3Modal from "web3modal";
// ----- Ethers V6 importlar -----
import {
  BrowserProvider,
  Contract,
  formatUnits // İleride stake formatlamak için kullanacağız
} from "ethers";

import "./App.css";

import ivyDaoABI from "./IvyDaoAbi.json";
import ivyTokenABI from "./ivytokenabi.json";
import ivyStakeABI from "./stakeabi.json";

import logo from "./daologo.png";
import welcomeImage from "./daologo.png";

// Yeni importlar (resimleri /src klasöründe bulundurun)
import metamaskLogo from "./metamask.png";
import trustwalletLogo from "./trustwallet.png";

const contractAddress = "0x83C5e32164Ad2597b1100B971FB668Cbc1f159c6";
const tokenAddress = "0xB00052f9B6dF3C88c56451D54799c0848E0e3778";
const stakeAddress = "0x83197e9Ae083bb95f981003dC09BF268311d1D19";
const nftAddress = "0xbedBE3221310ce84ddC1DAC4878d9D2f5890749F";

// IvyNFT için minimal ABI (sadece balanceOf fonksiyonunu içerir)
const ivyNFTMinimalABI = [
  "function balanceOf(address owner) view returns (uint256)",
];

/**
 * Wallet Connection Function (ORİJİNAL FONKSİYON)
 */
async function connectWallet(
  setIsConnected,
  setUserWalletAddress,
  setProvider,
  setIvyDaoContract,
  setIvyTokenContract,
  setIvyStakeContract,
  setIvyNFTContract,
  setUserStakedAmount,
  setUserHasNFT
) {
  // Web3Modal konfigürasyonu
  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: {},
  });

  try {
    const instance = await web3Modal.connect();
    // V6: Web3Provider yerine BrowserProvider kullanıyoruz
    const provider = new BrowserProvider(instance);

    // V6: getSigner() artık async olduğu için await kullanıyoruz
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const address = await signer.getAddress();

    // Sözleşme örnekleri (Contract importunu doğrudan kullandık)
    const ivyDaoContract = new Contract(contractAddress, ivyDaoABI.abi, signer);
    const ivyTokenContract = new Contract(tokenAddress, ivyTokenABI.abi, signer);
    const ivyStakeContract = new Contract(stakeAddress, ivyStakeABI.abi, signer);
    const ivyNFTContract = new Contract(nftAddress, ivyNFTMinimalABI, signer);

    setProvider(provider);
    setUserWalletAddress(address);
    setIsConnected(true);

    setIvyDaoContract(ivyDaoContract);
    setIvyTokenContract(ivyTokenContract);
    setIvyStakeContract(ivyStakeContract);
    setIvyNFTContract(ivyNFTContract);

    // Kullanıcının stake miktarını çek
    const stakedAmount = await ivyStakeContract.getTotalStakedByAddress(address);
    // stakedAmount artık bigint dönecek, stringe çevirip state'e koyuyoruz
    const stakedAmountString = stakedAmount.toString();
    setUserStakedAmount(stakedAmountString);

    // Kullanıcının NFT bakiyesini çek
    const nftBalance = await ivyNFTContract.balanceOf(address);
    // nftBalance da bigint
    const hasNFT = nftBalance > 0n; // 0n'dan büyükse NFT sahibi
    setUserHasNFT(hasNFT);

  } catch (error) {
    console.error("Wallet connection error:", error);
    alert(`Unable to connect wallet. Error: ${error.message}`);
  }
}

/**
 * Metamask ile bağlantı fonksiyonu
 */
async function connectWithMetamask(
  setIsConnected,
  setUserWalletAddress,
  setProvider,
  setIvyDaoContract,
  setIvyTokenContract,
  setIvyStakeContract,
  setIvyNFTContract,
  setUserStakedAmount,
  setUserHasNFT,
  setShowWalletOptions
) {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: false,
      providerOptions: {},
    });
    await web3Modal.clearCachedProvider();

    // Tarayıcıda MetaMask var mı
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      alert("Metamask bulunamadı ya da etkin değil!");
      return;
    }

    // Orijinal connectWallet fonksiyonunu çağır
    await connectWallet(
      setIsConnected,
      setUserWalletAddress,
      setProvider,
      setIvyDaoContract,
      setIvyTokenContract,
      setIvyStakeContract,
      setIvyNFTContract,
      setUserStakedAmount,
      setUserHasNFT
    );

    setShowWalletOptions(false);
  } catch (error) {
    console.error("connectWithMetamask error:", error);
  }
}

/**
 * Trust Wallet ile bağlantı fonksiyonu
 */
async function connectWithTrustWallet(
  setIsConnected,
  setUserWalletAddress,
  setProvider,
  setIvyDaoContract,
  setIvyTokenContract,
  setIvyStakeContract,
  setIvyNFTContract,
  setUserStakedAmount,
  setUserHasNFT,
  setShowWalletOptions
) {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: false,
      providerOptions: {},
    });
    await web3Modal.clearCachedProvider();

    if (!window.ethereum) {
      alert(
        "Trust Wallet bulunamadı veya etkin değil! Lütfen Trust Wallet dApp tarayıcısını veya eklentisini etkinleştirin."
      );
      return;
    }

    await connectWallet(
      setIsConnected,
      setUserWalletAddress,
      setProvider,
      setIvyDaoContract,
      setIvyTokenContract,
      setIvyStakeContract,
      setIvyNFTContract,
      setUserStakedAmount,
      setUserHasNFT
    );

    setShowWalletOptions(false);
  } catch (error) {
    console.error("connectWithTrustWallet error:", error);
  }
}

/**
 * Wallet Disconnection Function
 */
async function disconnectWallet(
  setIsConnected,
  setUserWalletAddress,
  setProvider,
  setUserHasNFT
) {
  setIsConnected(false);
  setUserWalletAddress("");
  setProvider(null);
  setUserHasNFT(false);

  const web3Modal = new Web3Modal();
  await web3Modal.clearCachedProvider();
}

/**
 * Opinions Section Component
 */
function OpinionsSection({
  opinions,
  setOpinions,
  userWalletAddress,
  handleAddOpinion,
}) {
  const [opinionText, setOpinionText] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const opinionsPerPage = 10;
  const totalPages = Math.ceil(opinions.length / opinionsPerPage);
  const indexOfLastOpinion = currentPage * opinionsPerPage;
  const indexOfFirstOpinion = indexOfLastOpinion - opinionsPerPage;
  const currentOpinions = opinions.slice(indexOfFirstOpinion, indexOfLastOpinion);

  const fetchOpinions = async () => {
    try {
      setLoading(true);
      const provider = new BrowserProvider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();

      const ivyDaoContract = new Contract(contractAddress, ivyDaoABI.abi, signer);

      const opinionCount = await ivyDaoContract.opinionCount();
      const opinionArray = [];

      for (let i = 1; i <= opinionCount; i++) {
        const opinion = await ivyDaoContract.opinions(i);
        opinionArray.push({
          id: opinion.opinionId.toString(),
          creator: opinion.creator,
          content: opinion.opinionContent,
          timestamp: new Date(
            Number(opinion.opinionTimestamp) * 1000
          ).toLocaleString(),
        });
      }

      // Yeni girilen en üstte gözüksün diye reverse
      setOpinions(opinionArray.reverse());
    } catch (error) {
      console.error("Error fetching opinions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Bileşen yüklendiğinde görüşleri çek
  useEffect(() => {
    fetchOpinions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Yeni opinion eklendikçe eventi yakala
  useEffect(() => {
    (async () => {
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const ivyDaoContract = new Contract(contractAddress, ivyDaoABI.abi, signer);

      const handleNewOpinion = (
        opinionId,
        creator,
        opinionContent,
        opinionTimestamp
      ) => {
        const newOpinion = {
          id: opinionId.toString(),
          creator,
          content: opinionContent,
          timestamp: new Date(Number(opinionTimestamp) * 1000).toLocaleString(),
        };
        setOpinions((prevOpinions) => [newOpinion, ...prevOpinions]);
      };

      ivyDaoContract.on("OpinionAdded", handleNewOpinion);

      return () => {
        ivyDaoContract.off("OpinionAdded", handleNewOpinion);
      };
    })();
  }, [setOpinions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const opinion = opinionText.trim();

    if (opinion.length >= 50 && opinion.length <= 500) {
      const success = await handleAddOpinion(opinion);
      if (success) {
        const newOpinion = {
          id: Date.now().toString(),
          content: opinion,
          creator: userWalletAddress,
          timestamp: new Date().toLocaleString(),
        };
        setOpinions([newOpinion, ...opinions]);
        setOpinionText("");
        setCharCount(0);
        setCurrentPage(1);
      }
    } else {
      alert("Your opinion must be between 50 and 500 characters.");
    }
  };

  const handleTextChange = (e) => {
    setOpinionText(e.target.value);
    setCharCount(e.target.value.length);
  };

  const maskWalletAddress = (address) => {
    if (address && address.length >= 10) {
      return `${address.slice(0, 5)}....${address.slice(-5)}`;
    }
    return address;
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="opinions-section">
      <form id="opinion-form" onSubmit={handleSubmit}>
        <textarea
          id="opinion-text"
          value={opinionText}
          onChange={handleTextChange}
          minLength={50}
          maxLength={500}
          required
          placeholder="Write your opinion here..."
        />
        <p>Minimum: 50 characters | Current: {charCount} characters</p>
        <button type="submit">Submit Opinion</button>
      </form>
      <div id="opinions-content">
        {loading ? (
          <p>Loading opinions...</p>
        ) : opinions.length === 0 ? (
          <p>No opinions yet.</p>
        ) : (
          currentOpinions.map((opinion) => (
            <div className="opinion" key={opinion.id}>
              <p>{opinion.content}</p>
              <p className="opinion-date">{opinion.timestamp}</p>
              <p className="wallet-address">
                {maskWalletAddress(opinion.creator)}
              </p>
            </div>
          ))
        )}
      </div>
      {totalPages > 1 && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            (pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => paginate(pageNumber)}
                className={`page-button ${
                  pageNumber === currentPage ? "active" : ""
                }`}
              >
                {pageNumber}
              </button>
            )
          )}
        </div>
      )}
    </div>
  );
}

/**
 * Poll Results Component
 */
function PollResults({ poll }) {
  const totalBurnedTokens = poll.votes.reduce((a, b) => a + Number(b), 0);

  return (
    <div className="results-list">
      {poll.options.map((option, idx) => {
        const tokenCountForOption = Number(poll.votes[idx]);
        const votePercentage =
          totalBurnedTokens > 0
            ? (tokenCountForOption / totalBurnedTokens) * 100
            : 0;

        return (
          <div className="result-item" key={idx}>
            <span>{`${option}: ${tokenCountForOption} Vote`}</span>
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{ width: `${votePercentage}%` }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

/**
 * Poll Detail Modal Component
 */
function PollDetailModal({
  pollDetail,
  setShowPollDetailModal,
  userStakedAmount,
  userHasNFT,
  userIvyBalance,
  setUserIvyBalance,
  setOngoingPolls,
  handleVote,
}) {
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    if (pollDetail && pollDetail.endDate > new Date()) {
      const timer = setInterval(() => {
        const now = new Date();
        const timeDiff = pollDetail.endDate - now;
        if (timeDiff <= 0) {
          setTimeRemaining("Poll has ended.");
          clearInterval(timer);
        } else {
          const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
          setTimeRemaining(
            `Time Remaining: ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
          );
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [pollDetail]);

  // Minimum stake for voting: 50 milyon IVY (50000000000 micro-ivy)
  const canVote = () => {
    try {
      const stakedAmountBN = BigInt(userStakedAmount || "0");
      const requiredStake = 50000000000n; // 50 milyon (micro) IVY
      return stakedAmountBN >= requiredStake || userHasNFT;
    } catch (error) {
      console.error("canVote error:", error);
      return false;
    }
  };

  const handleVoteSubmit = async (e) => {
    e.preventDefault();

    if (!canVote()) {
      alert(
        "You do not have enough stake to vote (minimum 50 million IVY) or do not own an Ivy Live NFT."
      );
      return;
    }

    const formData = new FormData(e.target);
    const selectedOptionIndex = parseInt(formData.get("poll-option"));
    const selectedVoteAmount = parseInt(formData.get("vote-amount"));

    if (isNaN(selectedOptionIndex)) {
      alert("Please select an option.");
      return;
    }

    if (isNaN(selectedVoteAmount)) {
      alert("Please select a valid vote package.");
      return;
    }

    const success = await handleVote(
      pollDetail.pollId,
      selectedOptionIndex,
      selectedVoteAmount
    );
    if (success) {
      alert(
        `You have used ${selectedVoteAmount} votes for "${pollDetail.options[selectedOptionIndex]}".`
      );
      setOngoingPolls((prev) => [...prev]);
    }
  };

  if (!pollDetail) return null;

  return (
    <div
      id="poll-detail-modal"
      className="modal"
      onClick={() => setShowPollDetailModal(false)}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span
          className="close-detail"
          onClick={() => setShowPollDetailModal(false)}
        >
          &times;
        </span>
        <div id="poll-detail-content">
          <h2>{pollDetail.title}</h2>
          <p>{pollDetail.description}</p>
          {pollDetail.endDate > new Date() ? (
            <>
              <p className="time-remaining">{timeRemaining}</p>
              <form className="vote-form" onSubmit={handleVoteSubmit}>
                {pollDetail.options.map((option, index) => (
                  <label key={index}>
                    <input
                      type="radio"
                      name="poll-option"
                      value={index}
                      required
                    />
                    {option}
                  </label>
                ))}
                <br />
                <label>
                  Select Vote Package:
                  <select name="vote-amount" required>
                    <option value="">--Select Package--</option>
                    <option value="1">1 Vote - 500,000 IVY</option>
                    <option value="10">10 Votes - 4,000,000 IVY</option>
                    <option value="25">25 Votes - 9,000,000 IVY</option>
                    <option value="50">50 Votes - 17,000,000 IVY</option>
                    <option value="100">100 Votes - 25,000,000 IVY</option>
                  </select>
                </label>
                <br />
                <button type="submit">Submit Vote</button>
              </form>
            </>
          ) : (
            <PollResults poll={pollDetail} />
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * Create New DAO Poll Modal Component
 */
function PollModal({ setShowPollModal, handleCreatePoll }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [options, setOptions] = useState(["", ""]);

  const maxOptions = 5;

  const handleAddOption = () => {
    if (options.length < maxOptions) {
      setOptions([...options, ""]);
    } else {
      alert("You can add up to 5 options.");
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const durationNumber = parseInt(duration, 10);

    if (title.trim().length < 10 || title.trim().length > 100) {
      alert("Title must be between 10 and 100 characters.");
      return;
    }
    if (description.trim().length < 50 || description.trim().length > 500) {
      alert("Description must be between 50 and 500 characters.");
      return;
    }
    if (options.length < 2 || options.length > 5) {
      alert("There must be between 2 and 5 options.");
      return;
    }
    for (let optionText of options) {
      const trimmedOption = optionText.trim();
      if (trimmedOption.length < 3 || trimmedOption.length > 90) {
        alert("Each option must be between 3 and 90 characters.");
        return;
      }
    }

    if (isNaN(durationNumber) || durationNumber < 10 || durationNumber > 90) {
      alert("Poll duration must be between 10 and 90 days.");
      return;
    }

    const currentDate = new Date();
    const endDate = new Date(
      currentDate.getTime() + durationNumber * 24 * 60 * 60 * 1000
    );

    const success = await handleCreatePoll(
      title.trim(),
      description.trim(),
      options.map((o) => o.trim()),
      endDate
    );
    if (success) {
      setTitle("");
      setDescription("");
      setDuration("");
      setOptions(["", ""]);
      setShowPollModal(false);
      alert("Poll created successfully!");
    }
  };

  return (
    <div
      id="poll-modal"
      className="modal"
      onClick={() => setShowPollModal(false)}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={() => setShowPollModal(false)}>
          &times;
        </span>
        <form id="poll-form" onSubmit={handleSubmit}>
          <h2>Create New DAO Poll</h2>
          <label>
            Title (10-100 characters):
            <input
              type="text"
              id="poll-title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              minLength={10}
              maxLength={100}
              required
            />
          </label>
          <label>
            Description (50-500 characters):
            <textarea
              id="poll-description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              minLength={50}
              maxLength={500}
              required
            />
          </label>
          <div id="options-container">
            {options.map((option, index) => (
              <input
                key={index}
                type="text"
                className="option-input"
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                minLength={3}
                maxLength={90}
                required
              />
            ))}
          </div>
          {options.length < maxOptions && (
            <button type="button" id="add-option-btn" onClick={handleAddOption}>
              Add Option
            </button>
          )}
          <label>
            Duration (days):{" "}
            <span className="duration-note">(min 10 | max 90 days)</span>
            <input
              type="number"
              id="poll-duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              min={10}
              max={90}
              step={1}
              required
            />
          </label>
          <button type="submit">Create Poll</button>
        </form>
      </div>
    </div>
  );
}

/**
 * Voting Section Component
 */
function VoteSection({
  userStakedAmount,
  userHasNFT,
  userIvyBalance,
  setUserIvyBalance,
  ongoingPolls,
  completedPolls,
  setShowPollModal,
  setPollDetail,
  setShowPollDetailModal,
}) {
  return (
    <div id="vote-section">
      <div className="poll-container">
        <h2>Ongoing Polls</h2>
        <div className="polls-container">
          {ongoingPolls.map((poll, index) => (
            <div
              className="poll-summary"
              key={index}
              onClick={() => {
                setPollDetail(poll);
                setShowPollDetailModal(true);
              }}
            >
              <h3>{poll.title}</h3>
            </div>
          ))}
        </div>
      </div>

      <div className="poll-container">
        <h2>Completed Polls</h2>
        <div className="polls-container">
          {completedPolls.map((poll, index) => (
            <div
              className="poll-summary"
              key={index}
              onClick={() => {
                setPollDetail(poll);
                setShowPollDetailModal(true);
              }}
            >
              <h3>{poll.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

/**
 * Main Application Component
 */
function App() {
  const [selectedPage, setSelectedPage] = useState("opinions");
  const [isConnected, setIsConnected] = useState(false);
  const [userWalletAddress, setUserWalletAddress] = useState("");
  const [provider, setProvider] = useState(null);

  const [ivyDaoContract, setIvyDaoContract] = useState(null);
  const [ivyTokenContract, setIvyTokenContract] = useState(null);
  const [ivyStakeContract, setIvyStakeContract] = useState(null);
  const [ivyNFTContract, setIvyNFTContract] = useState(null);

  const [userStakedAmount, setUserStakedAmount] = useState("0"); // micro-IVY (string)
  const [userHasNFT, setUserHasNFT] = useState(false);
  // Örnek bakiye, gerçek bakiye chain üzerinden de çekilebilir
  const [userIvyBalance, setUserIvyBalance] = useState("50000000");

  const [opinions, setOpinions] = useState([]);
  const [ongoingPolls, setOngoingPolls] = useState([]);
  const [completedPolls, setCompletedPolls] = useState([]);
  const [showPollModal, setShowPollModal] = useState(false);
  const [showPollDetailModal, setShowPollDetailModal] = useState(false);
  const [pollDetail, setPollDetail] = useState(null);

  // Yeni state: Cüzdan seçenekleri görünümü
  const [showWalletOptions, setShowWalletOptions] = useState(false);

  useEffect(() => {
    if (isConnected && ivyDaoContract) {
      (async () => {
        try {
          await loadPollsFromChain();
        } catch (err) {
          console.error("loadPollsFromChain error:", err);
          alert(
            "An error occurred while loading polls. Please check the contract address and your network connection."
          );
        }
      })();
    }
    // eslint-disable-next-line
  }, [ivyDaoContract, isConnected]);

  /**
   * Function to Load Polls from Blockchain
   */
  async function loadPollsFromChain() {
    if (!ivyDaoContract) return;
    try {
      const pollCount = await ivyDaoContract.getPollsCount();
      const now = new Date();
      const ongoing = [];
      const completed = [];

      for (let i = 1; i <= pollCount; i++) {
        const pollData = await ivyDaoContract.getPollDetails(i);
        const endTimestamp = Number(pollData.pollEndTimestamp) * 1000;
        const startTimestamp = Number(pollData.pollStartTimestamp) * 1000;

        const pollObj = {
          pollId: pollData.pollId.toString(),
          creator: pollData.creator,
          title: pollData.pollTitle,
          description: pollData.pollDescription,
          totalVotes: pollData.totalVotes.toString(),
          startDate: new Date(startTimestamp),
          endDate: new Date(endTimestamp),
          options: pollData.options,
          votes: pollData.votes.map((v) => v.toString()),
          totalIvyBurn: pollData.totalIvyBurn.toString(),
        };

        if (pollObj.endDate > now) {
          ongoing.push(pollObj);
        } else {
          completed.push(pollObj);
        }
      }

      setOngoingPolls(ongoing);
      setCompletedPolls(completed);
    } catch (error) {
      console.error("loadPollsFromChain error:", error);
      throw error;
    }
  }

  /**
   * Function to Add a New Opinion
   */
  async function handleAddOpinion(opinion) {
    if (!ivyDaoContract || !ivyTokenContract) return false;
    try {
      const opinionFee = await ivyDaoContract.OPINION_FEE();
      // opinionFee bigint dönecek
      console.log("Opinion Fee:", opinionFee.toString());

      // Kullanıcının token bakiyesi
      const userBalance = await ivyTokenContract.balanceOf(userWalletAddress);
      if (userBalance < opinionFee) {
        alert("You do not have enough tokens to add an opinion.");
        return false;
      }

      // Approve
      const txApprove = await ivyTokenContract.approve(contractAddress, opinionFee);
      console.log("Approval Transaction:", txApprove.hash);
      await txApprove.wait();
      console.log("Approval confirmed.");

      // Add opinion
      const tx = await ivyDaoContract.addOpinion(opinion);
      console.log("Add Opinion Transaction:", tx.hash);
      await tx.wait();
      console.log("Opinion added successfully.");

      return true;
    } catch (e) {
      if (e.data && e.data.message) {
        console.error("handleAddOpinion error:", e.data.message);
        alert(`An error occurred while adding the opinion: ${e.data.message}`);
      } else if (e.message) {
        console.error("handleAddOpinion error:", e.message);
        alert(`An error occurred while adding the opinion: ${e.message}`);
      } else {
        console.error("handleAddOpinion error:", e);
        alert("An error occurred while adding the opinion.");
      }
      return false;
    }
  }

  /**
   * Function to Create a New Poll
   */
  async function handleCreatePoll(title, description, options, endDate) {
    if (!ivyDaoContract || !ivyTokenContract) return false;
    try {
      const pollFee = await ivyDaoContract.POLL_FEE();
      console.log("Poll Fee:", pollFee.toString());

      const userBalance = await ivyTokenContract.balanceOf(userWalletAddress);
      if (userBalance < pollFee) {
        alert("You do not have enough tokens to create a poll.");
        return false;
      }

      const now = Math.floor(Date.now() / 1000);
      const endTime = Math.floor(endDate.getTime() / 1000);

      // Süre kontrolü
      const durationInDays = (endTime - now) / (60 * 60 * 24);
      if (durationInDays < 10 || durationInDays > 90) {
        alert("Poll duration must be between 10 and 90 days.");
        return false;
      }

      // Approve
      const txApprove = await ivyTokenContract.approve(contractAddress, pollFee);
      console.log("Approval Transaction:", txApprove.hash);
      await txApprove.wait();
      console.log("Approval confirmed.");

      const tx = await ivyDaoContract.createPoll(
        title,
        description,
        now,
        endTime,
        options
      );
      console.log("Create Poll Transaction:", tx.hash);
      await tx.wait();
      console.log("Poll created successfully.");

      // Reload polls
      await loadPollsFromChain();
      return true;
    } catch (e) {
      if (e.data && e.data.message) {
        console.error("handleCreatePoll error:", e.data.message);
        alert(`An error occurred while creating the poll: ${e.data.message}`);
      } else if (e.message) {
        console.error("handleCreatePoll error:", e.message);
        alert(`An error occurred while creating the poll: ${e.message}`);
      } else {
        console.error("handleCreatePoll error:", e);
        alert("An error occurred while creating the poll.");
      }
      return false;
    }
  }

  /**
   * Function to Vote on a Poll
   */
  async function handleVote(pollId, optionIndex, numVotes) {
    if (!ivyDaoContract || !ivyTokenContract) return false;
    try {
      // Farklı oy paket maliyetlerini chain'den çekiyoruz
      const oneVoteCost = await ivyDaoContract.ONE_VOTE_COST();
      const tenVotesCost = await ivyDaoContract.TEN_VOTES_COST();
      const twentyFiveVotesCost = await ivyDaoContract.TWENTY_FIVE_VOTES_COST();
      const fiftyVotesCost = await ivyDaoContract.FIFTY_VOTES_COST();
      const hundredVotesCost = await ivyDaoContract.HUNDRED_VOTES_COST();

      let voteCost = 0n;
      if (numVotes === 1) voteCost = oneVoteCost;
      else if (numVotes === 10) voteCost = tenVotesCost;
      else if (numVotes === 25) voteCost = twentyFiveVotesCost;
      else if (numVotes === 50) voteCost = fiftyVotesCost;
      else if (numVotes === 100) voteCost = hundredVotesCost;
      else {
        alert("Invalid vote package.");
        return false;
      }

      console.log("Vote Cost:", voteCost.toString());

      const userBalance = await ivyTokenContract.balanceOf(userWalletAddress);
      if (userBalance < voteCost) {
        alert("You do not have enough tokens to vote.");
        return false;
      }

      const txApprove = await ivyTokenContract.approve(contractAddress, voteCost);
      console.log("Approval Transaction:", txApprove.hash);
      await txApprove.wait();
      console.log("Approval confirmed.");

      const tx = await ivyDaoContract.voteOnPoll(pollId, optionIndex, numVotes);
      console.log("Vote Transaction:", tx.hash);
      await tx.wait();
      console.log("Vote cast successfully.");

      await loadPollsFromChain();
      return true;
    } catch (e) {
      if (e.data && e.data.message) {
        console.error("handleVote error:", e.data.message);
        alert(`An error occurred while voting: ${e.data.message}`);
      } else if (e.message) {
        console.error("handleVote error:", e.message);
        alert(`An error occurred while voting: ${e.message}`);
      } else {
        console.error("handleVote error:", e);
        alert("An error occurred while voting.");
      }
      return false;
    }
  }

  /**
   * Function to Update Poll Status (tamamlanan anketler vs.)
   */
  useEffect(() => {
    const interval = setInterval(updatePollStatus, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [ongoingPolls, completedPolls]);

  const updatePollStatus = () => {
    const currentDate = new Date();
    setOngoingPolls((prevOngoingPolls) => {
      const updatedOngoingPolls = [];
      const movedToCompleted = [];

      prevOngoingPolls.forEach((poll) => {
        if (currentDate >= poll.endDate) {
          movedToCompleted.push(poll);
        } else {
          updatedOngoingPolls.push(poll);
        }
      });

      if (movedToCompleted.length > 0) {
        setCompletedPolls((prevCompletedPolls) => {
          const newCompletedPolls = movedToCompleted.filter(
            (p) => !prevCompletedPolls.some((cp) => cp.pollId === p.pollId)
          );
          return [...newCompletedPolls, ...prevCompletedPolls];
        });
      }

      return updatedOngoingPolls;
    });
  };

  /**
   * Function to Check Poll Creation Permission (500 milyon IVY veya NFT sahibi)
   */
  const canCreatePoll = () => {
    try {
      const stakedAmountBN = BigInt(userStakedAmount || "0");
      const requiredStake = 500000000000000n; // 500 milyon (micro) IVY
      return stakedAmountBN >= requiredStake || userHasNFT;
    } catch (error) {
      console.error("canCreatePoll error:", error);
      return false;
    }
  };

  const handleCreatePollClick = () => {
    if (canCreatePoll()) {
      setShowPollModal(true);
    } else {
      alert(
        "To create a survey, you must stake a minimum of 500 million IVY tokens or own at least one Ivy Live NFT."
      );
    }
  };

  /**
   * Function to Mask Wallet Address in Navbar
   */
  const maskWalletAddressNav = (address) => {
    if (address && address.length >= 10) {
      return `${address.slice(0, 5)}....${address.slice(-5)}`;
    }
    return address;
  };

  return (
    <div className="App">
      {!isConnected ? (
        <div id="popup-overlay">
          <div className="welcome-container">
            <img src={welcomeImage} alt="Welcome" className="welcome-image" />
            <h1>Welcome to IVY Live Dao</h1>
            <button
              id="connect-wallet-btn"
              onClick={() => setShowWalletOptions(true)}
            >
              Connect Wallet
            </button>

            {showWalletOptions && (
              <div
                style={{
                  border: "2px solid white",
                  display: "flex",
                  justifyContent: "center",
                  gap: "40px",
                  marginTop: "20px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    connectWithMetamask(
                      setIsConnected,
                      setUserWalletAddress,
                      setProvider,
                      setIvyDaoContract,
                      setIvyTokenContract,
                      setIvyStakeContract,
                      setIvyNFTContract,
                      setUserStakedAmount,
                      setUserHasNFT,
                      setShowWalletOptions
                    )
                  }
                >
                  <img
                    src={metamaskLogo}
                    alt="Metamask"
                    style={{ width: "50px" }}
                  />
                  <span style={{ color: "white", marginTop: "5px" }}>
                    Metamask
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    connectWithTrustWallet(
                      setIsConnected,
                      setUserWalletAddress,
                      setProvider,
                      setIvyDaoContract,
                      setIvyTokenContract,
                      setIvyStakeContract,
                      setIvyNFTContract,
                      setUserStakedAmount,
                      setUserHasNFT,
                      setShowWalletOptions
                    )
                  }
                >
                  <img
                    src={trustwalletLogo}
                    alt="Trust Wallet"
                    style={{ width: "50px" }}
                  />
                  <span style={{ color: "white", marginTop: "5px" }}>
                    TrustWallet
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <nav id="nav-menu">
            <div className="nav-left">
              <button id="create-dao-btn" onClick={handleCreatePollClick}>
                Create DAO
              </button>
              <button
                id="opinions-btn"
                className={selectedPage === "opinions" ? "active" : ""}
                onClick={() => setSelectedPage("opinions")}
              >
                Opinions
              </button>
              <button
                id="vote-btn"
                className={selectedPage === "vote" ? "active" : ""}
                onClick={() => setSelectedPage("vote")}
              >
                Voting
              </button>
            </div>
            <div className="nav-middle">
              <img src={logo} alt="Logo" className="nav-logo" />
            </div>
            <div className="nav-right">
              <span id="wallet-address">
                {maskWalletAddressNav(userWalletAddress)}
              </span>
              <span style={{ marginLeft: "10px" }}>
                {/* userStakedAmount bir string (bigint decimal). formatUnits() ile gösterelim */}
                Stake: {formatUnits(BigInt(userStakedAmount || "0"), 6)} IVY
              </span>
              {userHasNFT && (
                <span style={{ marginLeft: "10px", color: "green" }}>
                  <strong>🖼️ NFT Holder</strong>
                </span>
              )}
              <button
                id="disconnect-btn"
                onClick={() =>
                  disconnectWallet(
                    setIsConnected,
                    setUserWalletAddress,
                    setProvider,
                    setUserHasNFT
                  )
                }
              >
                Disconnect
              </button>
            </div>
          </nav>
          <div id="main-content">
            {selectedPage === "opinions" && (
              <OpinionsSection
                opinions={opinions}
                setOpinions={setOpinions}
                userWalletAddress={userWalletAddress}
                handleAddOpinion={handleAddOpinion}
              />
            )}
            {selectedPage === "vote" && (
              <VoteSection
                userStakedAmount={userStakedAmount}
                userHasNFT={userHasNFT}
                userIvyBalance={userIvyBalance}
                setUserIvyBalance={setUserIvyBalance}
                ongoingPolls={ongoingPolls}
                completedPolls={completedPolls}
                setShowPollModal={setShowPollModal}
                setPollDetail={setPollDetail}
                setShowPollDetailModal={setShowPollDetailModal}
              />
            )}
          </div>
          {showPollModal && (
            <PollModal
              setShowPollModal={setShowPollModal}
              handleCreatePoll={handleCreatePoll}
            />
          )}
          {showPollDetailModal && pollDetail && (
            <PollDetailModal
              pollDetail={pollDetail}
              setShowPollDetailModal={setShowPollDetailModal}
              userStakedAmount={userStakedAmount}
              userHasNFT={userHasNFT}
              userIvyBalance={userIvyBalance}
              setUserIvyBalance={setUserIvyBalance}
              setOngoingPolls={setOngoingPolls}
              handleVote={handleVote}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default App;
